@import '../../styles/global.scss';

.footer {
  .top-wrapper {
    background-color: #efefef;

    @include desktop {
      display: grid;
      grid-template-columns: 25% 75%;
    }

    .legal-wrapper {
      background-color: $dark-green;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;

      @include desktop {
        padding: 32px;
      }
      .legal {
        margin: 0;
        padding: 0;
        gap: 1rem;
        width: fit-content;
        display: flex;
        @include desktop {
          flex-direction: column;
        }

        li {
          list-style: none;
          line-height: 1;

          .link {
            color: $white;
            text-decoration: none;
            font-family: $roboto;
          }
        }
      }
    }

    .footer-description {
      padding: 32px;

      .description {
        @include fullhd {
          width: 90%;
        }

        @include ultrahd {
          width: 66%;
        }

        a {
          font-family: $roboto-bold;
          text-decoration: none;
        }
      }
    }
  }

  .bottom-wrapper {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .credits {
      font-size: 12px;
      color: $black;
      line-height: 15px;
    }
  }
}
