$dark-green: #395418;
$light-green: #86b53b;
$black: #000000;
$white: #ffffff;

$column-gap: 16px;

$ultrahd: 1920px;

$roboto: 'Roboto', arial, helvetica, sans-serif;
$roboto-bold: 'Roboto Bold', arial, helvetica, sans-serif;

$roboto-slab: 'Roboto Slab', arial, helvetica, sans-serif;
$roboto-slab-medium: 'Roboto Slab Medium', arial, helvetica, sans-serif;
$roboto-slab-semibold: 'Roboto Slab SemiBold', arial, helvetica, sans-serif;

@import './mixins.scss';
@import '../../node_modules/bulma/sass/utilities/mixins.scss';
