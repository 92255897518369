@import '../../styles/global.scss';

.navbar {
  height: 85px;
  width: 100%;
  background-color: $white;

  position: relative;
  z-index: 40;
  @include touch {
    display: flex;
    align-items: center;
  }

  @include desktop {
    display: grid;

    grid-template-columns: 50% 50%;

    @include widescreen {
      grid-template-columns: 60% 40%;
    }
    @include fullhd {
      grid-template-columns: 65% 35%;
    }
  }

  .text-wrapper {
    display: flex;
    align-items: center;
    padding: 20px 40px 20px 24px;

    @include fullhd {
      padding: 24px 70px 24px 28px;
    }

    @include ultrahd {
      padding: 24px 160px 24px 28px;
    }
  }

  .logos {
    display: none;

    padding: 0 40px 0 24px;

    @include fullhd {
      padding: 0 70px 0 28px;
    }
    @include ultrahd {
      padding: 0 160px 0 28px;
    }

    @include desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 32px;
    }

    .logo {
      height: 50px;
    }

    .logo-wrapper {
      position: relative;

      .logo-link {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }

  .back-btn {
    position: absolute;
    bottom: -50px;
    left: 0;
  }
}
