.embla-prev,
.embla-next {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  img {
    filter: invert(1);
  }
  transition: opacity 0.3s ease;
  &:disabled {
    opacity: 0.3;
    cursor: auto;
  }
}
