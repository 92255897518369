@import '../../../styles/global.scss';
.audio-wrapper {
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 16px;
  margin-top: 2rem;

  .audio-button {
    width: 42px;
    height: 42px;
    background-color: #ebebeb;
    display: flex;
    border: none;
    padding: 0;

    cursor: pointer;

    audio {
      opacity: 0;
    }
  }

  .audio-span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $dark-green;
  }
}
