@import '../../styles/global.scss';

.modal {
  position: fixed;
  top: 0;
  height: 100dvh;

  background-color: $white;
  z-index: 41;

  @include desktop {
    box-shadow: 0px 0px 20px #00000029;
  }
  width: 100%;
  overflow: hidden;

  @include desktop {
    width: 45%;
    max-width: 808px;
  }

  .modal-info-wrapper {
    width: 100%;
    height: 100svh;
    overflow-y: auto;
    padding-top: 34px;
    @include tablet {
      padding-top: 85px;
    }
  }

  .information-wrapper {
    overflow: hidden;
    padding: 0 32px 32px;

    @include tablet {
      padding: 0 40px 40px;
    }

    @include fullhd {
      padding: 0 60px 60px;
    }

    .upper-line {
      display: block;
      font-family: $roboto-slab-semibold;
      color: $dark-green;
      text-decoration: dotted;

      font-size: 1rem;
      letter-spacing: 0.1em;
      line-height: 1.25rem;

      @include tablet {
        font-size: 1.375rem;
        line-height: 1.75rem;
      }
    }

    .content {
      line-height: 26px;
      * {
        line-height: inherit;
      }
    }
  }

  .media-wrapper {
    padding: 0 32px 32px 0;
    display: flex;
    flex-direction: column;
    gap: 32px;
    overflow: hidden;

    @include tablet {
      padding: 0 40px 40px 0;
      gap: 40px;
    }
    @include fullhd {
      padding: 0 60px 60px 0;
      gap: 60px;
    }

    video {
      aspect-ratio: 16 / 9;
    }
  }

  &[data-direction='left'] {
    left: 0;
  }
  &[data-direction='right'] {
    right: 0;

    .media-wrapper {
      padding: 0 0 32px 32px;

      @include tablet {
        padding: 0 0 40px 40px;
      }

      @include fullhd {
        padding: 0 0 60px 60px;
        gap: 60px;
      }
    }
  }

  .spinner {
    border: 0.4rem solid rgba($dark-green, 0.2);
    border-left: 0.4rem solid $dark-green;
    font-size: 1rem;
    display: inline-flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    text-indent: -9999em;
    animation: loading 1.1s infinite linear;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;

    &::after {
      border-radius: inherit;
      width: 5rem;
      height: 5rem;
    }
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
