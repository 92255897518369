@import '../../../styles/global.scss';

.close-btn {
  position: absolute;
  cursor: pointer;
  background: $dark-green;
  border: none;
  top: 0;
  padding: 0;
  z-index: 1;

  &[data-direction='left'] {
    right: 0;
  }
  &[data-direction='right'] {
    left: 0;
  }

  width: 32px;
  height: 32px;

  @include tablet {
    width: 40px;
    height: 40px;
  }

  @include fullhd {
    width: 60px;
    height: 60px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
