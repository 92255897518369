@import '../../styles/global.scss';
.touch-placeholder {
  height: calc(100svh - 85px);
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-end;

  .background-media-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom;
    }

    .mobile-bg {
      display: none;
      @include mobile {
        display: block;
      }
    }

    .tablet-bg {
      display: none;
      @include tablet-only {
        display: block;
      }
    }

    .desktop-bg {
      display: none;
      @include desktop {
        display: block;
      }
    }
  }

  .map-enter-info {
    position: relative;
    z-index: 1;
    background-image: url('../../Images/cloud_bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: flex-end;

    width: 344px;
    height: 119px;
    padding: 22px 32px;

    @include tablet {
      padding: 32px;
      width: 596px;
      height: 205px;
    }

    .text {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.36px;
      color: $white;
      font-family: $roboto-slab-medium;

      @include tablet {
        font-size: 32px;
        letter-spacing: 0.64px;
        line-height: 56px;
      }
    }

    .enter-btn {
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: none;
      padding: 0;
      top: 0;
      right: 110px;

      @include tablet {
        top: 47px;
        right: 219px;
      }
    }
  }
}
