@import '../../../styles/global.scss';

.copy-right {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  text-align: right;
  z-index: 5;
  background-color: rgba(#000, 0.3);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  clip-path: inset(0 calc(100% - 25px) 0 0);
  transition: clip-path 0.5s ease;

  &:hover {
    clip-path: inset(0 0 0 0);
  }

  &::before {
    content: '©';
    color: $white;
    padding-right: 0.5rem;
    font-size: 0.75rem;
    line-height: 1;
  }
  color: $white;
  font-size: 0.75rem;
  line-height: 1;
}
