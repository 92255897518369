// Font Families
@font-face {
  font-family: Roboto;
  src:
    local('Roboto'),
    url('../fonts/roboto/roboto-v30-latin-regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Roboto Bold;
  src:
    local('Roboto Bold'),
    url('../fonts/roboto/roboto-v30-latin-700.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Roboto Slab;
  src:
    local('Roboto Slab'),
    url('../fonts/roboto-slab/roboto-slab-v34-latin-regular.woff2')
      format('woff2');
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Roboto Slab Medium;
  src:
    local('Roboto Slab Medium'),
    url('../fonts/roboto-slab/roboto-slab-v34-latin-500.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Roboto Slab SemiBold;
  src:
    local('Roboto Slab SemiBold'),
    url('../fonts/roboto-slab/roboto-slab-v34-latin-600.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}
