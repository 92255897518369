@import '../../../styles/global.scss';

.video-player {
  position: relative;

  &[data-is-playing='true'] {
    .toggle-button {
      opacity: 0;
    }
  }

  &:hover {
    .toggle-button {
      opacity: 1;
    }
  }

  .toggle-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(0, 0, 0) translateX(-50%) translateY(-50%);
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: opacity 0.3s ease;

    img {
      width: 74px;
      height: 74px;
    }
  }

  .seek-bar {
    z-index: 10;
    position: absolute;
    transition: opacity 0.3s ease-in;
    width: 95%;

    height: 5px;
    bottom: 2.3125rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    display: flex;
    background: rgba($white, 0.3);
    cursor: pointer;

    .progress {
      display: inline-block;
      width: 1px;
      height: 5px;
      background: $white;
      border-radius: 10px;
    }
  }
}
