@import '../../../styles/global.scss';

.point {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  background-color: $white;
  border: none;
  cursor: pointer;

  box-shadow: 0px 0px 2px 2px $white;
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba($light-green, 1);
  }
  100% {
    box-shadow: 0 0 0 20px rgba($light-green, 0);
  }
}
