@import '../../styles/global.scss';

.button {
  display: flex;
  padding: 0.875rem 2rem;
  background-color: $dark-green;
  border: 1.5px solid $dark-green;
  color: $white;
  font-family: $roboto-slab;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.1em;
  align-items: first baseline;
  text-decoration: none;

  transition:
    background-color 0.5s ease,
    color 0.5s ease;

  &:hover {
    background-color: $white;
    color: $dark-green;

    .arrow-wrapper {
      .white {
        opacity: 0;
      }

      .green {
        opacity: 1;
      }
    }
  }

  .arrow-wrapper {
    height: 10px;
    width: 10px;
    display: flex;
    margin-right: 12px;
    position: relative;

    img {
      position: absolute;
      inset: 0;
      object-fit: contain;
      transition: opacity 0.3s ease;
    }

    .white {
      opacity: 1;
    }

    .green {
      opacity: 0;
    }
  }
}
