@import '../../../styles/global.scss';

.slider {
  position: relative;
  .embla {
    overflow: hidden;

    .embla-container {
      display: flex;
    }

    .embla-slide {
      flex: 0 0 100%;
      min-width: 0;

      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }

      position: relative;
      aspect-ratio: 3 / 2;
      img {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .slider-action-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 3% 5%;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(black, 0.3) 100%
    );
    .bullet-wrapper {
      display: flex;
      gap: 0.5rem;

      .bullet {
        height: 3px;
        width: 1rem;
        background-color: rgba($white, 0.4);
        border: none;
        padding: 0;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s ease;

        &.active {
          background-color: $white;
          scale: 1.1;
          width: 18px;
        }
      }
    }
  }
}
