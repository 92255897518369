@import '../../../styles/global.scss';

.nav-arrow {
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  pointer-events: none;

  .nav-btn {
    pointer-events: all;
    cursor: pointer;
    background: none;
    padding: 0;
    border: none;
    margin: 0;
    display: flex;
    transition: opacity 0.3s ease;

    &[data-is-disabled='true'] {
      opacity: 0.5;
    }
  }

  .left,
  .right {
    display: block;
    width: 15px;
    height: 30px;

    @include tablet {
      width: 20px;
      height: 40px;
    }

    @include ultrahd {
      width: 30px;
      height: 60px;
    }
  }

  &[data-direction='X'] {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;

    .right {
      transform: rotate(180deg);
    }
  }

  &[data-direction='Y'] {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    flex-direction: column;
    .left {
      transform: rotate(90deg);
    }
    .right {
      transform: rotate(270deg);
    }
  }
}
