html {
  scroll-behavior: auto;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

body {
  color: $black;
  background-color: $white;
  overflow-x: clip;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
  font-family: $roboto;

  * {
    outline: none;
  }

  *::selection {
    color: $white;
    background: $dark-green;
    text-shadow: none;
  }
}

h1 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: $dark-green;
  font-family: $roboto-bold;
  margin: 0;

  @include tablet {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @include desktop {
    font-size: 1.0625rem;
    line-height: 1.375rem;
  }

  @include fullhd {
    font-size: 1.375rem;
    line-height: 2.375rem;
  }

  @include ultrahd {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }
}

h2 {
  font-family: $roboto-slab;
  font-size: 1.5rem;
  line-height: 2rem;
  color: $black;
  margin: 0;
  margin-bottom: 1.25rem;

  @include tablet {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }
}

.is-relative {
  position: relative;
}

p {
  font-family: $roboto;
  font-size: 1rem;
  line-height: 1.75rem;
  margin: 0;

  strong {
    font-family: $roboto-bold;
    font-size: inherit;
    line-height: inherit;
    color: $dark-green;
  }

  a {
    color: $dark-green;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.5s ease;
    &:hover {
      color: rgba($dark-green, 0.8);
    }
  }
}
