@import '../../styles/global.scss';

.animal-map-wrapper {
  width: 100%;
  display: flex;
  overflow: hidden;
  perspective: 1px;

  &[data-is-touch='true'] {
    top: 0;
    left: 0;
    z-index: 40;
    position: fixed;
    height: 100svh;
    .exit-map {
      display: block;
    }
  }

  &[data-is-touch='false'] {
    position: relative;
    height: calc(100svh - 85px);

    .exit-map {
      display: none;
    }
  }

  .animal-map {
    position: absolute;
    top: 0;
    left: 0;
    perspective: 1px;
    touch-action: none;

    .background-media-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      video {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }

  .exit-map {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
    background: none;
    border: none;
    cursor: pointer;
  }
}
